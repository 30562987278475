// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"e0d1ecf0a180a4b0e5c58c59b4e548b186cdb4c2"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "/assets/jg-pages-edge";import * as Sentry from '@sentry/nextjs';

Sentry.init({
  environment: process.env.ENVIRONMENT,
  dsn: 'https://cb229deb403d401b9db5d3a008a60427@o106326.ingest.us.sentry.io/4505228284854272',

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.1,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  replaysOnErrorSampleRate: 0.5,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0,

  // You can remove this option if you're not planning to use the Sentry Session Replay feature:
  integrations: [
    Sentry.replayIntegration({
      // Additional Replay configuration goes in here, for example:
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  ignoreErrors: [
    /CookieConsent/,
    'Load failed',
    /twttr/,
    /Illegal invocation/,
    /ChunkLoadError/,
    /heap.push is not a function/,
    /Blocked a frame with origin/,
    /Can't find variable: IFrameMessageHandler/,
    /evaluating 'a.L'/,
    'bb',
    /**
     * React internal error thrown when something outside react modifies the DOM
     * This is usually because of a browser extension or Chrome's built-in translate
     */
    "NotFoundError: Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.",
    "NotFoundError: Failed to execute 'insertBefore' on 'Node': The node before which the new node is to be inserted is not a child of this node.",
    /Unexpected token '<'/,
    'jb',
  ],
});
